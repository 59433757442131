/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { encryptedUserState } from "recoil/atoms";

const LoginCheck = () => {
  const navigate = useNavigate();
  const encryptedUser = useRecoilValue(encryptedUserState);
  const isLogin = useMemo(() => !!encryptedUser, [encryptedUser]);

  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, [isLogin]);

  if (isLogin) return <Outlet />;
  return null;
};

export default LoginCheck;
