/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { type QueryFunctionContext } from "react-query";

export interface IUser {
  id: number;
  email: string;
  password: string;
  name: string;
  token: string;
}

export const USER_KEY = { email: "email", password: "password" };

export const ADMIN_URL = {
  Common: process.env.REACT_APP_SUPERCLUB_COMMON_ADMIN_URL,
  Club: process.env.REACT_APP_SUPERCLUB_CLUB_ADMIN_URL,
  Sns: process.env.REACT_APP_SUPERCLUB_SNS_ADMIN_URL,
  Forum: process.env.REACT_APP_SUPERCLUB_FORUM_ADMIN_URL,
  Post: process.env.REACT_APP_SUPERCLUB_POST_ADMIN_URL,
  ClubCategory: process.env.REACT_APP_SUPERCLUB_CLUB_CATEGGORY_ADMIN_URL,
  ForumCategory: process.env.REACT_APP_SUPERCLUB_FOURM_CATEGGORY_ADMIN_URL,
  Advertisement: process.env.REACT_APP_SUPERCLUB_ADVERTISEMENT_ADMIN_URL,
  Vote: process.env.REACT_APP_SUPERCLUB_VOTE_ADMIN_URL,
};

export const fetchUserData = async ({
  queryKey,
}: QueryFunctionContext<[string, { email: string; password: string }]>): Promise<IUser> => {
  try {
    console.log("fetchUserData", queryKey);
    const email = queryKey[1].email;
    const password = queryKey[1].password;

    const mockData: IUser = {
      id: 1,
      name: "Alice",
      email,
      password,
      token: "token",
    };

    // throw new Error("에러 발생!");
    // return mockData after 1 seconds
    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        if (email === "1234" && password === "1234") {
          reject(new Error("에러 발생!"));
        } else {
          resolve(mockData);
        }
      }, 1500);
    });
  } catch (error) {
    return await Promise.reject(error);
  }
};
