import { AuthStorage, getStorage, removeStorage, setStorage } from "constants/constants";
import React, { PropsWithChildren, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import cretaApi from "service/cretaApi";

type SessionCheckProps = PropsWithChildren;

const SessionCheck = (props: SessionCheckProps) => {
  const { children } = props;

  const navigate = useNavigate();
  const rf = getStorage(AuthStorage.REFRESH_TOKEN) || "";

  const alreadyFetch = useRef(false);

  const removeAndNavigate = () => {
    removeStorage(AuthStorage.REFRESH_TOKEN);
    removeStorage(AuthStorage.ACCESS_TOKEN);

    navigate("/login");
  };

  const { mutate } = useMutation({
    mutationFn: cretaApi.postSessionRefresh,

    onSuccess: (response) => {
      const { sessionToken, refreshToken } = response;
      setStorage(AuthStorage.ACCESS_TOKEN, sessionToken);
      setStorage(AuthStorage.REFRESH_TOKEN, refreshToken);
    },
    onError: removeAndNavigate,
  });

  useEffect(() => {
    if (alreadyFetch.current) return;
    if (!rf) return;

    alreadyFetch.current = true;
    mutate({ refreshToken: rf });
  }, [rf]);

  return <>{children}</>;
};

export default SessionCheck;
