import React from "react";
import { ADMIN_URL } from "service/userApi";
import useAdminIframe from "hooks/useAdminIframe";

const Sns = () => {
  useAdminIframe("SnsIframe", ADMIN_URL.Sns);

  return (
    <div className="flex flex-1 flex-col">
      <iframe id="SnsIframe" title="Sns" src="" width="100%" height="100%" className="flex-1" />
    </div>
  );
};

export default Sns;
