import React, { useState, type FC } from "react";
import { Outlet } from "react-router-dom";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SessionCheck from "router/SessionCheck";

const App: FC = () => {
  const [client] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: 0,
        },
      },
    }),
  );

  return (
    <div>
      <QueryClientProvider client={client}>
        <SessionCheck>
          <Outlet />
        </SessionCheck>
      </QueryClientProvider>
    </div>
  );
};

export default App;
