/**
 * cookie, localStorage 에 들어가는 enum (key값)
 */
export enum AuthStorage {
  ACCESS_TOKEN = "accessToken_v2",
  REFRESH_TOKEN = "refreshToken",
}

/** ------------------------------------------------------------------------------
 * 
 * cookie
 * 
 ------------------------------------------------------------------------------ */
export const getCookie = (cName: string): string => {
  const name = `${cName}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const setCookie = (cName: string, cValue: string): void => {
  const expiration = new Date();
  expiration.setTime(new Date().getTime() + 3600000 * 24 * 30 * 3 /*7*/);
  document.cookie = `${cName}=${cValue};Secure;SameSite=Strict;Path=/;Expires=${expiration.toUTCString()}`;
};

export const removeCookie = (cName: string): void => {
  const expiration = new Date();
  expiration.setTime(0);
  document.cookie = `${cName}=;Secure;SameSite=Strict;Path=/;Expires=${expiration.toUTCString()}`;
};

/** ------------------------------------------------------------------------------
 * 
 * 여긴 localStorage
 * 
 ------------------------------------------------------------------------------ */
export const setStorage = (name: string, value: string) => window.localStorage.setItem(name, value);
export const getStorage = (name: string) => window.localStorage.getItem(name);
export const removeStorage = (name: string) => window.localStorage.removeItem(name);

/** ------------------------------------------------------------------------------
 * 
 * 여긴 sessionStorage
 * 
 ------------------------------------------------------------------------------ */
export const setSessionStorage = (name: string, value: string) => window.sessionStorage.setItem(name, value);
export const getSessionStorage = (name: string) => window.sessionStorage.getItem(name);
export const removeSessionStorage = (name: string) => window.sessionStorage.removeItem(name);
