// hooks/useAdminIframe.ts
import { useEffect } from "react";

const useAdminIframe = (iframeId: string, url?: string) => {
  useEffect(() => {
    if (!url) {
      console.error("No URL provided");
      return;
    }
    const token = localStorage.getItem("accessToken_v2");
    if (token) {
      const adminIframe = document.getElementById(iframeId) as HTMLIFrameElement | null;
      if (adminIframe) {
        adminIframe.src = `${url}?token=${token}`;
      } else {
        console.error("No iframe element found");
      }
    } else {
      console.error("No access token found");
    }
  }, [iframeId, url]);
};

export default useAdminIframe;
