import React from "react";
import { ADMIN_URL } from "service/userApi";
import useAdminIframe from "hooks/useAdminIframe";

const Vote = () => {
  useAdminIframe("VoteIframe", ADMIN_URL.Vote);

  return (
    <div className="flex flex-1 flex-col">
      <iframe id="VoteIframe" title="Vote" src="" width="100%" height="100%" className="flex-1" />
    </div>
  );
};

export default Vote;
