import axios from "axios";

export const METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const DASHBOARD_BASE_URL = `https://api.superclubs.io`;

const dashboardAxios = axios.create({
  baseURL: DASHBOARD_BASE_URL,
});

export { dashboardAxios };
