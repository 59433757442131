import React from "react";
import { ADMIN_URL } from "service/userApi";
import useAdminIframe from "hooks/useAdminIframe";

const ForumCategory = () => {
  useAdminIframe("ForumCategoryIframe", ADMIN_URL.ForumCategory);

  return (
    <div className="flex flex-1 flex-col">
      <iframe id="ForumCategoryIframe" title="Club" src="" width="100%" height="100%" className="flex-1" />
    </div>
  );
};

export default ForumCategory;
