import React from "react";
interface AdminTableProps<TData, TKey extends string> {
  title: TKey[];
  values: { [key in TKey]: (data: TData) => string | JSX.Element };
  data: TData[];
  className?: string;
  widths?: number[];

  isLoading?: boolean;
}

const AdminTable = <TData, TKey extends string>(props: AdminTableProps<TData, TKey>) => {
  const { data, title, values, className, widths, isLoading } = props;
  return (
    <div className={className}>
      <table className="mb-6 table w-full table-fixed whitespace-normal rounded-lg bg-white text-center align-middle">
        {widths && widths.length === title.length && (
          <colgroup>
            {widths.map((width, i) => (
              <col key={`${i}_col_${width}`} width={width + "%"} />
            ))}
          </colgroup>
        )}

        <thead>
          <tr className="rounded-t-lg">
            {title.map((v, i) => {
              return (
                <th
                  className="relative whitespace-nowrap break-words p-4 text-[14px] before:absolute before:right-0 before:top-1/2 before:h-5 before:w-px before:-translate-y-1/2 before:bg-gray-300"
                  key={`${v}_${i}_th`}
                >
                  {/* <span className="h-5 w-px bg-gray-300" /> */}
                  <p className="w-full">{v}</p>
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {isLoading &&
            [...new Array(7)].map((_, i) => (
              <tr key={`${i}_loading`} className="w-full bg-black">
                <td colSpan={title.length}>
                  <div className="w-full bg-gray-100" />
                </td>
              </tr>
            ))}

          {!isLoading &&
            !!data?.length &&
            data.map((data, i) => {
              return (
                <tr className="border-b border-gray-300" key={`tr_${i}`}>
                  {title.map((title) => {
                    const tdClassName = "whitespace-normal break-words text-14 p-4";
                    const value = values[title](data);
                    return (
                      <td className={tdClassName} key={`${i}_${value}_${title}`}>
                        <React.Fragment key={i}>{value ?? <></>}</React.Fragment>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminTable;
