import React from "react";
import AdminTable from "./AdminTable";
import { bottomTableResult } from "./mock";
import { useGetDashboardPostComment } from "hooks/dashboard/use-query";
import { DASHBOARD_BASE_URL } from "api/dashboard.config";

const Bottom = () => {
  const mock = bottomTableResult;

  const { data } = useGetDashboardPostComment();

  return (
    <div className="">
      <div className="flex items-center justify-end">
        <button
          className="rounded-md bg-white px-7 py-4 active:bg-gray-200"
          onClick={() => {
            window.open(`${DASHBOARD_BASE_URL}/dashboard/post_comment/excel`, "_blank");
          }}
        >
          Download data
        </button>
      </div>

      <div className="max-w-[calc(100vw-264px)] overflow-x-auto p-6">
        <AdminTable
          data={data}
          className="min-w-[2400px]"
          title={[
            "Date",
            "New Posts",
            "My Home Posts",
            "Club Posts",
            "Forum Posts",
            "Total Posts",
            "New Comments",
            "My Home Comments",
            "Club Comments",
            "Forum Comments",
            "Total Comments",
          ]}
          values={{
            Date: (data: any) => data.date,
            "New Posts": (data: any) => data.new_posts.toLocaleString(),
            "My Home Posts": (data: any) => data.my_home_posts.toLocaleString(),
            "Club Posts": (data: any) => data.club_posts.toLocaleString(),
            "Forum Posts": (data: any) => data.forum_posts.toLocaleString(),
            "Total Posts": (data: any) => data.total_posts.toLocaleString(),
            "New Comments": (data: any) => data.new_comments.toLocaleString(),
            "My Home Comments": (data: any) => data.my_home_comments.toLocaleString(),
            "Club Comments": (data: any) => data.club_comments.toLocaleString(),
            "Forum Comments": (data: any) => data.forum_comments.toLocaleString(),
            "Total Comments": (data: any) => data.total_comments.toLocaleString(),
          }}
        />
      </div>
    </div>
  );
};

export default Bottom;
