import React from "react";
import { ADMIN_URL } from "service/userApi";
import useAdminIframe from "hooks/useAdminIframe";

const Advertisement = () => {
  useAdminIframe("AdvertisementIframe", ADMIN_URL.Advertisement);

  return (
    <div className="flex flex-1 flex-col">
      <iframe id="AdvertisementIframe" title="Post" src="" width="100%" height="100%" className="flex-1" />
    </div>
  );
};

export default Advertisement;
