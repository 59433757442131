import React from "react";
import Top from "components/dashboard/top";
import Mid from "components/dashboard/Mid";
import Bottom from "components/dashboard/Bottom";

const Dashboard = () => {
  return (
    <div className="min-h-[calc(100vh-100px)] w-full bg-gray-100" style={{ maxHeight: "max-content" }}>
      <div className="mx-auto max-w-7xl">
        <div className="h-12" />
        <Top />
        <div className="h-12" />

        <Mid />
        <div className="h-12" />
        <Bottom />
      </div>
    </div>
  );
};

export default Dashboard;
