import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";
import CryptoJS from "crypto-js";
import { USER_KEY } from "service/userApi";

const { persistAtom } = recoilPersist();

export const encryptedUserState = atom({
  key: "encryptedUserState",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const decryptedUserState = selector({
  key: "decryptedUserState",
  get: ({ get }) => {
    const encryptedUser = get(encryptedUserState);
    try {
      const ret = {
        email: CryptoJS.AES.decrypt(encryptedUser.email, USER_KEY.email).toString(CryptoJS.enc.Utf8),
        password: CryptoJS.AES.decrypt(encryptedUser.password, USER_KEY.password).toString(CryptoJS.enc.Utf8),
      };
      return ret;
    } catch (error) {
      return undefined;
    }
  },
});

export const loginState = atom({
  key: "loginState",
  default: false,
});
