/* eslint-disable @typescript-eslint/no-unused-vars */
import type React from "react";
import { type MouseEvent, useState } from "react";
import { ReactComponent as SupportIcon } from "images/ic-support.svg";
import { ReactComponent as ArrowDownIcon } from "images/ic-arrow-down.svg";
import useToggle from "hooks/common/useToggle";
import classNames from "classnames";

const Footer: React.FC = () => {
  return null;
  // const {isOn: isOpenLanguageLayer, toggle: toggleLanguageLayer} = useToggle();

  // return (
  //   <footer className="absolute bottom-0 left-0 right-0 flex h-[80px] justify-center border-t-[1px] border-white border-opacity-30 bg-transparent">
  //     <div className="flex min-w-[1280px] items-center justify-between bg-pink-400 px-[50px]">
  //       <div>
  //         <span className="text-sm font-medium text-white">ⓒ 2023 Create.All rights reserved.</span>
  //       </div>

  //       <div className="flex gap-7">
  //         <button>
  //           <span className="text-sm text-white">Terms of Service</span>
  //         </button>
  //         <button>
  //           <span className="text-sm text-white">Privacy Policy</span>
  //         </button>
  //         <button>
  //           <SupportIcon />
  //           <span className="text-sm text-white">Support</span>
  //         </button>

  //         <div className="relative flex">
  //           <button className="flex items-center" onClick={toggleLanguageLayer}>
  //             <img src="https://cdn-icons-png.flaticon.com/512/555/555526.png" className="mr-2 h-4 w-5" />
  //             <span className="mr-3 text-sm text-white">English</span>
  //             <ArrowDownIcon
  //               className={classNames("transform", {"rotate-180": isOpenLanguageLayer}, "transition duration-300")}
  //             />
  //           </button>

  //           <div
  //             className="absolute bottom-10 -right-10  flex  max-h-[80px]  min-h-[40px] w-44 flex-col  rounded-lg  border  border-gray-300  bg-white  shadow-md  before:absolute
  //                        before:-bottom-[6.5px]  before:left-1/2  before:-translate-x-1/2  before:border-x-[5.5px]  before:border-t-[6.5px]  before:border-x-transparent  before:border-t-gray-300  after:absolute
  //                        after:-bottom-[5px]  after:left-1/2  after:-translate-x-1/2  after:border-x-[4px]  after:border-t-[5px]  after:border-x-transparent  after:border-t-white">
  //             <button className="flex items-center  px-4  py-2  text-sm  text-gray-800  hover:bg-gray-100  hover:text-gray-900"></button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </footer>
  // );
};

export default Footer;
