import axios from "axios";
import { AuthStorage, getStorage } from "constants/constants";

export interface PostCredentialLoginPayload {
  email: string;
  password: string;
  authProvider: "credential";
}

const axiosApi = axios.create({
  baseURL: `${process.env.REACT_APP_CRETA_AUTH_API}`,
});

const cretaApi = {
  postCredentialLogin: async (payload: PostCredentialLoginPayload) => {
    const { data } = await axiosApi("/api/credential/login", {
      method: "POST",
      data: payload,
    });
    return data;
  },
  getSessionValidate: async (token: string) => {
    const { data } = await axiosApi("/api/session/validate", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  },
  postSessionRefresh: async ({ refreshToken }: { refreshToken: string }) => {
    const { data } = await axiosApi("/api/session/refresh", {
      method: "POST",
      data: {
        refreshToken,
      },
    });
    return data;
  },
};

export default cretaApi;

/** ------------------------------------------------------------------------------
 * 
 * 패스워드 SHA-256 알고리즘으로 해시
 * 
 ------------------------------------------------------------------------------ */
export const hashPassword = async (password: string) => {
  // window에 crypto.subtle 객체가 없다면 평문으로 리턴
  if (!globalThis.window?.crypto?.subtle) {
    return password;
  }

  // 비밀번호를 UTF-8 문자열로 인코딩
  const encoder = new TextEncoder();
  const data = encoder.encode(password);
  // SHA-256 알고리즘을 사용하여 데이터를 해시
  const hash = await window.crypto.subtle.digest("SHA-256", data);

  // 해시된 데이터를 16진수 문자열로 변환
  const hashArray = Array.from(new Uint8Array(hash));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");

  return hashHex;
};
