import React from "react";

interface TotalCommentCardProps {
  data: {
    total_comments: number;
    my_home: number; // or sns
    club: number;
    forum: number;
  };
}

const TotalCommentCard = (props: TotalCommentCardProps) => {
  const { data } = props;
  if (!data) return <></>;

  return (
    <div className="block min-h-[338px] w-full grow rounded-[20px] bg-white px-4 py-6">
      <div
        className="flex flex-col rounded-[20px] bg-[#4318FF] px-4 pb-4"
        style={{
          background: "linear-gradient(135deg, #0000ff 50%, #4318FF 55%)",
        }}
      >
        <p className="mt-4 text-xs font-medium text-gray-400">Total Comments</p>
        <p className="mt-0.5 text-[34px] font-bold leading-[42px] text-white">{data.total_comments.toLocaleString()}</p>
      </div>

      <div className="mt-8 flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <p className="text-sm font-bold">My Home</p>
          <p className="text-sm font-bold">{data.my_home.toLocaleString()}</p>
        </div>

        <div className="h-px w-full bg-gray-200" />

        <div className="flex items-center justify-between">
          <p className="text-sm font-bold">Club</p>
          <p className="text-sm font-bold">{data.club.toLocaleString()}</p>
        </div>
        <div className="h-px w-full bg-gray-200" />

        <div className="flex items-center justify-between">
          <p className="text-sm font-bold">Forum</p>
          <p className="text-sm font-bold">{data.forum.toLocaleString()}</p>
        </div>

        <div className="h-px w-full bg-gray-200" />
      </div>
    </div>
  );
};

export default TotalCommentCard;
