import axios from "axios";

const axiosApi = axios.create({
  baseURL: `${process.env.REACT_APP_COMMON_API}/v1`,
});

const commonApi = {
  getAdminUserStatus: async (session: { expiresAt: number; refreshToken: string; sessionToken: string }) => {
    const { data } = await axiosApi({
      url: "/admin/user/status",
      headers: {
        Authorization: `Bearer ${session.sessionToken}`,
      },
    });
    return data;
  },
};

export default commonApi;
