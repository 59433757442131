// components/Post.tsx
import React from "react";
import { ADMIN_URL } from "service/userApi";
import useAdminIframe from "hooks/useAdminIframe";

const Post = () => {
  useAdminIframe("PostIframe", ADMIN_URL.Post);

  return (
    <div className="flex flex-1 flex-col">
      <iframe id="PostIframe" title="Post" src="" width="100%" height="100%" className="flex-1" />
    </div>
  );
};

export default Post;
