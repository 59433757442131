import React, { useEffect } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import App from "../App";
import Main from "pages/Main";
import Login from "pages/Login";
import Common from "../pages/Common";
import Club from "pages/Club";
import Sns from "pages/Sns";
import Forum from "pages/Forum";
import LoginCheck from "components/LoginCheck";
import Dashboard from "pages/Dashboard";
import ForumCategory from "../pages/ForumCategory";
import ClubCategory from "../pages/ClubCategory";
import Post from "../pages/Post";
import Vote from "../pages/Vote";
import Advertisement from "../pages/Advertisement";

const ErrorTemp = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return null;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorTemp />,
    children: [
      {
        path: "/",
        element: <LoginCheck />,
        children: [
          {
            path: "/",
            element: <Main />,
            children: [
              {
                path: "/dashboard",
                element: <Dashboard />,
              },
              {
                path: "/common",
                element: <Common />,
              },
              {
                path: "/club",
                element: <Club />,
              },
              {
                path: "/forum",
                element: <Forum />,
              },
              {
                path: "/sns",
                element: <Sns />,
              },
              {
                path: "/post",
                element: <Post />,
              },
              {
                path: "/club-category",
                element: <ClubCategory />,
              },
              {
                path: "/forum-category",
                element: <ForumCategory />,
              },
              {
                path: "/vote",
                element: <Vote />,
              },
              {
                path: "/advertisement",
                element: <Advertisement />,
              },
            ],
          },
        ],
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
]);

export default router;
