import React from "react";
import { tableResult } from "./mock";
import AdminTable from "./AdminTable";
import { useGetDashboardGeneral } from "hooks/dashboard/use-query";
import { DASHBOARD_BASE_URL } from "api/dashboard.config";

const Mid = () => {
  const mock = tableResult;

  const { data } = useGetDashboardGeneral();

  return (
    <div className="">
      <div className="flex items-center justify-end">
        <button
          className="rounded-md bg-white px-7 py-4 active:bg-gray-200"
          onClick={() => {
            window.open(`${DASHBOARD_BASE_URL}/dashboard/general/excel`, "_blank");
          }}
        >
          Download data
        </button>
      </div>

      <div className="max-w-[calc(100vw-264px)] overflow-x-auto p-6">
        <AdminTable
          data={data}
          className="min-w-[2400px]"
          title={[
            "Date",
            "New Member",
            "Withdrawal",
            "Total Members",
            "New UV",
            "New Clubs",
            "Total Clubs",
            "New Club Membership",
            "Total Club Membership",
            "New Forum",
            "Total Forum",
          ]}
          values={{
            Date: (data: any) => data.date,
            "New Member": (data: any) => data.new_member.toLocaleString(),
            Withdrawal: (data: any) => data.withdrawal.toLocaleString(),
            "Total Members": (data: any) => data.total_member.toLocaleString(),
            "New UV": (data: any) => data.new_visitor.toLocaleString(),
            "New Clubs": (data: any) => data.new_clubs.toLocaleString(),
            "Total Clubs": (data: any) => data.total_clubs.toLocaleString(),
            "New Club Membership": (data: any) => data.new_club_membership.toLocaleString(),
            "Total Club Membership": (data: any) => data.total_club_membership.toLocaleString(),
            "New Forum": (data: any) => data.new_forum.toLocaleString(),
            "Total Forum": (data: any) => data.total_forum.toLocaleString(),
          }}
        />
      </div>
    </div>
  );
};

export default Mid;
