import React from "react";
import TotalMembersCard from "./TotalMembers";
import UVCard from "./UVCard";
import TotalPostCard from "./TotalPostCard";
import TotalCommentCard from "./TotalCommentCard";
import { useGetDashboard } from "hooks/dashboard/use-query";

const Top = () => {
  const { data } = useGetDashboard();

  return (
    <div className="grid w-full max-w-7xl grid-cols-4 gap-8">
      <TotalMembersCard data={data?.total_member} />
      {/* <UVCard data={data?.user_visitor} /> */}
      <TotalPostCard data={data?.post_count} />
      <TotalCommentCard data={data?.comment_count} />
    </div>
  );
};

export default Top;
