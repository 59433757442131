// components/Common.tsx
import React from "react";
import { ADMIN_URL } from "service/userApi";
import useAdminIframe from "hooks/useAdminIframe";

const Common = () => {
  useAdminIframe("CommonIframe", ADMIN_URL.Common);

  return (
    <div className="flex flex-1 flex-col">
      <iframe id="CommonIframe" title="Common" src="" width="100%" height="100%" className="flex-1" />
    </div>
  );
};

export default Common;
