import React from "react";
import { ReactComponent as HeaderLogo } from "images/header-logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { encryptedUserState } from "recoil/atoms";

const Header = () => {
  const setEncryptedUser = useSetRecoilState(encryptedUserState);
  const navigate = useNavigate();
  const menus = [
    { key: 1, title: "Common", link: "/common" },
    { key: 2, title: "Club", link: "/club" },
    { key: 3, title: "Forum", link: "/forum" },
    { key: 5, title: "Post", link: "/post" },
    { key: 6, title: "Club Category", link: "/club-category" },
    { key: 7, title: "Forum Category", link: "/forum-category" },
    { key: 8, title: "Advertisement", link: "/advertisement" },
  ];

  const onClickLogo = () => {
    navigate("/");
  };

  const logout = () => {
    setEncryptedUser(undefined);
  };

  return (
    <div className="h-[100px] w-full min-w-[1280px] justify-center bg-slate-200">
      <div className="mx-auto flex w-[1280px] items-center">
        {/* LOGO */}
        <button
          className="mr-[40px] w-[180px] items-center justify-center self-stretch hover:bg-slate-300"
          onClick={onClickLogo}
        >
          <HeaderLogo className=" h-[90px] w-[80px] " />
        </button>

        {menus.map((menu) => {
          return (
            <NavLink
              key={menu.key}
              to={menu.link}
              className={({ isActive }) =>
                `flex items-center self-stretch bg-inherit p-[0_18px] text-[15px] font-semibold text-[text-black]
                hover:bg-slate-300
                ${isActive ? `text-primary-100` : ""}`
              }
            >
              {menu.title}
            </NavLink>
          );
        })}

        <div className="flex-1" />

        <button className="" onClick={logout}>
          logout
        </button>
      </div>
    </div>
  );
};

export default Header;
