import { useQuery } from "@tanstack/react-query";
import dashboardApi from "api/dashboard";

export const useGetDashboard = () => {
  return useQuery({
    queryKey: ["dashboard"],
    queryFn: () => dashboardApi.getDashboard(),
  });
};

export const useGetDashboardGeneral = () => {
  return useQuery({
    queryKey: ["dashboardGeneral"],
    queryFn: () => dashboardApi.getDashboardGeneral(),
  });
};

export const useGetDashboardPostComment = () => {
  return useQuery({
    queryKey: ["dashboardPostComment"],
    queryFn: () => dashboardApi.getDashboardPostComment(),
  });
};
