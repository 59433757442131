import { dashboardAxios, METHOD } from "./dashboard.config";

const dashboardApi = {
  getDashboard: async () => {
    const { data } = await dashboardAxios({
      url: "/dashboard/",
      method: METHOD.GET,
    });
    return data;
  },

  getDashboardGeneral: async () => {
    const { data } = await dashboardAxios({
      url: "/dashboard/general/",
      method: METHOD.GET,
    });
    return data;
  },

  /**
   * GET 맞는지 확인
   */
  getDashboardGeneralExcel: async () => {
    const { data } = await dashboardAxios({
      url: "/dashboard/general/excel",
      method: METHOD.GET,
    });
    return data;
  },

  getDashboardPostComment: async () => {
    const { data } = await dashboardAxios({
      url: "/dashboard/post_comment/",
      method: METHOD.GET,
    });
    return data;
  },

  /**
   * GET 맞는지 확인
   */
  getDashboardPostCommentExcel: async () => {
    const { data } = await dashboardAxios({
      url: "/dashboard/post_comment/excel",
      method: METHOD.GET,
    });
    return data;
  },
};

export default dashboardApi;
