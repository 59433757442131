// components/Post.tsx
import React from "react";
import { ADMIN_URL } from "service/userApi";
import useAdminIframe from "hooks/useAdminIframe";

const Forum = () => {
  useAdminIframe("ForumIframe", ADMIN_URL.Forum);

  return (
    <div className="flex flex-1 flex-col">
      <iframe id="ForumIframe" title="Post" src="" width="100%" height="100%" className="flex-1" />
    </div>
  );
};

export default Forum;
